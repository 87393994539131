<template>
  <div>Verifying...</div>
</template>

<script>
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
export default {
  components: {},
  mounted() {
    /*if (this.$auth.isAuthenticated) {
      const claims = this.$auth.getIdTokenClaims();
      // if you need the raw id_token, you can access it
      // using the __raw property
      const id_token = claims.__raw;

      console.log(`auth0 callback id_token: ${id_token}`);
    }*/

    //console.log("auth0: " + this.$auth.isAuthenticated);

    this.$store
      .dispatch(VERIFY_AUTH)
      .then(data => {
        //console.log("verify auth");
        //console.log(data);
        if (data) {
          this.$router.push({ name: "dashboard" });
        } else {
          this.$router.push({ name: "login" });
        }
      })
      .catch(() => {
        //console.log("error");
        //console.log(error);

        this.$router.push({ name: "login" });
      });
  }
};
</script>
